var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// Lib
import React, { lazy, Suspense } from "react";
import { unescapeString } from "../lib/unescapeString";
// Components
var Rating = lazy(function () { return import('./Rating'); });
var LatestReview = lazy(function () { return import('./LatestReview'); });
import { RatingIcons } from "../interfaces/SearchPage";
var VenueHit = /** @class */ (function (_super) {
    __extends(VenueHit, _super);
    function VenueHit() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            collapseOpen: false,
            latestReview: null,
            reviewNotFound: false
        };
        _this.reviewDiv = React.createRef();
        _this.concatTypes = function (types) { return types.join(', '); };
        _this.concatCuisines = function (hit) {
            var cuisines = __spreadArray(__spreadArray([], [hit.primary_cuisine], false), hit.other_cuisine, true);
            cuisines = __spreadArray([], Array.from(new Set(cuisines)), true);
            return cuisines.join(', ');
        };
        _this.getCuisineName = function (id) {
            if (typeof window.cuisineType === "undefined")
                return id;
            var matches = window.cuisineType.filter(function (cuisine) { return "".concat(cuisine.term_id) === "".concat(id); });
            return typeof matches[0] !== 'undefined' ? matches[0].name : id;
        };
        _this.getWrapperClass = function (hit) {
            var classes = ["venue"];
            if (hit.full_member)
                classes.push('venue--member');
            return classes.join(' ');
        };
        _this.render = function () {
            var _a;
            var hit = _this.props.hit;
            var wrapperClass = _this.getWrapperClass(hit);
            var cuisineString = _this.getCuisineName(hit.primary_cuisine);
            return (React.createElement("div", { className: wrapperClass },
                hit.image_url && (React.createElement("a", { href: hit.permalink, className: "venue__image" },
                    React.createElement("img", { src: hit.image_url, alt: hit.post_title }))),
                React.createElement("a", { href: hit.permalink, className: "venue__content" },
                    React.createElement(Suspense, { fallback: React.createElement("span", { className: "venue__skeleton" }) },
                        React.createElement("div", null,
                            hit.full_member === 1 && (React.createElement("svg", { className: "venue__member" },
                                React.createElement("use", { xlinkHref: "#icon--verified" }))),
                            React.createElement("h3", { className: "venue__title" },
                                " ",
                                unescapeString(hit.post_title),
                                " "),
                            React.createElement("div", { className: "venue__ratings" },
                                React.createElement(Rating, { number: hit.rating_score || 0, icon: RatingIcons.Heart, fillRest: true }),
                                React.createElement(Rating, { number: hit.price_rating || 0, icon: RatingIcons.Cash, fillRest: false })),
                            React.createElement("div", { className: "venue__cuisines" }, cuisineString)))),
                React.createElement("div", { className: "venue__review", ref: _this.reviewDiv }, !hit.latest_review_title.length && !hit.latest_review_content.length ? (React.createElement("span", null,
                    React.createElement("a", { href: hit.permalink }, "Click here for more details")) // <span>Venue Not reviewed yet</span>
                ) : (React.createElement(Suspense, { fallback: React.createElement("span", null, "Loading") },
                    React.createElement(LatestReview, { reviewTitle: hit.latest_review_title, reviewContent: hit.latest_review_content, reviewID: hit.latest_review_id, reviewDate: hit.latest_review_date, venueURL: (_a = hit.permalink) !== null && _a !== void 0 ? _a : '' }))))));
        };
        return _this;
    }
    return VenueHit;
}(React.PureComponent));
export default VenueHit;
