import qs from "qs";
export var urlToSearchState = function (queryString) {
    var queryObject = qs.parse(queryString, { ignoreQueryPrefix: true });
    var _a = queryObject.s, s = _a === void 0 ? '' : _a, _b = queryObject.page, page = _b === void 0 ? 1 : _b, _c = queryObject.menu_free_from, menu_free_from = _c === void 0 ? [] : _c, _d = queryObject.kitchen_free_from, kitchen_free_from = _d === void 0 ? [] : _d, _e = queryObject.venue_type, venue_type = _e === void 0 ? [] : _e, _f = queryObject.primary_cuisine, primary_cuisine = _f === void 0 ? [] : _f, _g = queryObject.other_cuisine, other_cuisine = _g === void 0 ? [] : _g, _h = queryObject.venue_feature, venue_feature = _h === void 0 ? [] : _h, _j = queryObject.meal_type, meal_type = _j === void 0 ? [] : _j;
    var searchState = { refinementList: {} };
    var menuFreeFrom = Array.isArray(menu_free_from) ? menu_free_from : [menu_free_from].filter(Boolean);
    var kitchenFreeFrom = Array.isArray(kitchen_free_from) ? kitchen_free_from : [kitchen_free_from].filter(Boolean);
    var venueType = Array.isArray(venue_type) ? venue_type : [venue_type].filter(Boolean);
    var primaryCuisine = Array.isArray(primary_cuisine) ? primary_cuisine : [primary_cuisine].filter(Boolean);
    var otherCuisine = Array.isArray(other_cuisine) ? other_cuisine : [other_cuisine].filter(Boolean);
    var venueFeature = Array.isArray(venue_feature) ? venue_feature : [venue_feature].filter(Boolean);
    var mealType = Array.isArray(meal_type) ? meal_type : [meal_type].filter(Boolean);
    if (s) {
        searchState.query = s;
    }
    if (page) {
        searchState.page = page;
    }
    if (menuFreeFrom.length) {
        searchState.refinementList.menu_free_from = menuFreeFrom.map(decodeURIComponent);
    }
    if (kitchenFreeFrom.length) {
        searchState.refinementList.kitchen_free_from = kitchenFreeFrom.map(decodeURIComponent);
    }
    if (venueType.length) {
        searchState.refinementList.venue_type = venueType.map(decodeURIComponent);
    }
    if (primaryCuisine.length) {
        searchState.refinementList.primary_cuisine = primaryCuisine.map(decodeURIComponent);
    }
    if (otherCuisine.length) {
        searchState.refinementList.other_cuisine = otherCuisine.map(decodeURIComponent);
    }
    if (venueFeature.length) {
        searchState.refinementList.venue_feature = venueFeature.map(decodeURIComponent);
    }
    if (mealType.length) {
        searchState.refinementList.meal_type = mealType.map(decodeURIComponent);
    }
    return searchState;
};
