import qs from "qs";
export var searchStateToURL = function (searchState) {
    var newQueryState = {
        s: searchState.query || '',
        page: searchState.page || 1,
        latLng: searchState.configure.aroundLatLng || undefined,
        radius: searchState.configure.aroundRadius || undefined,
        menu_free_from: searchState.refinementList && searchState.refinementList.menu_free_from,
        kitchen_free_from: searchState.refinementList && searchState.refinementList.kitchen_free_from,
        venue_type: searchState.refinementList && searchState.refinementList.venue_type,
        primary_cuisine: searchState.refinementList && searchState.refinementList.primary_cuisine,
        other_cuisine: searchState.refinementList && searchState.refinementList.other_cuisine,
        venue_feature: searchState.refinementList && searchState.refinementList.venue_feature,
        meal_type: searchState.refinementList && searchState.refinementList.meal_type,
    };
    return qs.stringify(newQueryState);
};
