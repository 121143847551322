import qs from "qs";
var getLatLngFromURL = function (queryString) {
    var queryObject = qs.parse(queryString, { ignoreQueryPrefix: true });
    if (typeof queryObject.latLng !== "undefined") {
        return queryObject.latLng;
    }
    return null;
};
var getRadiusFromURL = function (queryString) {
    var queryObject = qs.parse(queryString, { ignoreQueryPrefix: true });
    if (typeof queryObject.radius !== "undefined") {
        return parseInt(queryObject.radius);
    }
    return null;
};
var getAreaFromURL = function (queryString) {
    var queryObject = qs.parse(queryString, { ignoreQueryPrefix: true });
    if (typeof queryObject.latLng !== "undefined") {
        return queryObject.area;
    }
    return null;
};
export { getLatLngFromURL, getRadiusFromURL, getAreaFromURL };
