var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// Lib
import React, { lazy, Suspense } from "react";
import { InstantSearch, Pagination, SearchBox, Configure, SortBy, connectStats, connectStateResults } from 'react-instantsearch-dom';
import qs from "qs";
import { urlToSearchState } from "../lib/urlToSearchState";
import { searchStateToURL } from "../lib/searchStateToURL";
import { getAreaFromURL, getLatLngFromURL, getRadiusFromURL } from "../lib/queryStringHelpers";
// @ts-ignore
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
// Components
var LocalitySearch = lazy(function () { return import('../components/LocalitySearch'); });
var TaxonomySelection = lazy(function () { return import('../components/TaxonomySelection'); });
var NoVenueResults = lazy(function () { return import('../components/NoResults'); });
import VenueHit from "../components/VenueHit";
// Config
import { TypeSenseConfig } from "../../TypeSenseConfig";
var typesenseInstantSearchAdapter = new TypesenseInstantSearchAdapter(TypeSenseConfig);
var searchClient = typesenseInstantSearchAdapter.searchClient;
var SearchPage = /** @class */ (function (_super) {
    __extends(SearchPage, _super);
    function SearchPage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            searchState: urlToSearchState(window.location.search),
            latLng: getLatLngFromURL(window.location.search),
            radius: getRadiusFromURL(window.location.search),
            locationInput: null,
            selectedArea: getAreaFromURL(window.location.search),
            locationModalOpen: false,
            allergenModalOpen: false,
            filtersModalOpen: false
        };
        _this.debouncedSetState = null;
        _this.defaultFallback = function () {
            return (React.createElement("div", { className: "loading loading--small" },
                React.createElement("svg", { className: "loading-animation loading__icon" },
                    React.createElement("use", { xlinkHref: "#icon--loading" }))));
        };
        _this.onPopState = function () {
            var state = urlToSearchState(window.location.search);
            _this.setState({ searchState: state || null });
        };
        _this.onSearchStateChange = function (searchState) {
            clearTimeout(_this.debouncedSetState);
            window.scrollTo(0, 0);
            _this.debouncedSetState = setTimeout(function () {
                window.history.pushState(searchState, null, "?".concat(searchStateToURL(searchState)));
            }, 700);
            _this.setState({ searchState: searchState });
        };
        _this.changeLatLng = function (resp) {
            var newState = {
                query: '',
                page: "1",
            };
            var latLng = [resp.geometry.location.lat || null, resp.geometry.location.lng || null].join(', ');
            _this.setState({
                searchState: newState,
                latLng: latLng,
                radius: 35000,
                selectedArea: resp.name,
                locationModalOpen: false,
                allergenModalOpen: true,
            });
        };
        _this.updateRefinements = function (refinementLabel, values) {
            var _a;
            var configuration = _this.generateConfigObject();
            var newSearchState = __assign(__assign({}, _this.state.searchState), { refinementList: __assign(__assign({}, _this.state.searchState.refinementList), (_a = {}, _a[refinementLabel] = values, _a)), page: "1", configure: configuration });
            _this.onSearchStateChange(newSearchState);
        };
        _this.generateConfigObject = function () {
            var configuration = {
                hitsPerPage: 10,
                distinct: true,
                enablePersonalization: true
            };
            if (_this.state.latLng !== null) {
                configuration.aroundLatLng = _this.state.latLng;
                configuration.aroundRadius = _this.state.radius || 35000;
            }
            return configuration;
        };
        _this.getFilterHeader = function () {
            return (React.createElement("nav", { id: "site-nav", className: "site-nav" },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "site-nav__inner" },
                        React.createElement("div", { className: "site-nav__left" },
                            React.createElement("button", { type: "button", className: "back-btn", onClick: function () { return _this.toggleFilterModal({
                                    allergenModalOpen: false,
                                    filtersModalOpen: false
                                }, false); } },
                                React.createElement("span", null, "Back"),
                                React.createElement("svg", null,
                                    React.createElement("use", { xlinkHref: "#icon--back" })))),
                        React.createElement("div", { className: "site-nav__center" },
                            React.createElement("h2", null, "Filters")),
                        React.createElement("div", { className: "site-nav__right" })))));
        };
        _this.toggleFilterModal = function (value, toggleState) {
            var eventName = toggleState ? 'filter.modal.open' : 'filter.modal.close';
            var event = new Event(eventName);
            document.body.dispatchEvent(event);
            _this.setState(value);
        };
        _this.getNumberOfSelectedAllergies = function () {
            var _a, _b, _c, _d;
            var count = 0;
            if (typeof ((_a = _this.state.searchState.refinementList) === null || _a === void 0 ? void 0 : _a.kitchen_free_from) !== 'undefined')
                count += (_b = _this.state.searchState.refinementList) === null || _b === void 0 ? void 0 : _b.kitchen_free_from.length;
            if (typeof ((_c = _this.state.searchState.refinementList) === null || _c === void 0 ? void 0 : _c.menu_free_from) !== 'undefined')
                count += (_d = _this.state.searchState.refinementList) === null || _d === void 0 ? void 0 : _d.menu_free_from.length;
            return count === 0 ? "" : "".concat(count);
        };
        _this.getHitsLayout = function () {
            return function (_a) {
                var searchResults = _a.searchResults, isSearchStalled = _a.isSearchStalled;
                if (searchResults === null)
                    return React.createElement(React.Fragment, null);
                if (isSearchStalled) {
                    return (React.createElement("div", { className: "search__loading loading" },
                        React.createElement("svg", { className: "loading-animation loading__icon" },
                            React.createElement("use", { xlinkHref: "#icon--loading" }))));
                }
                if (searchResults.nbHits !== null && searchResults.nbHits > 0) {
                    return (React.createElement("section", { id: "main", role: "main", className: "search__results" },
                        React.createElement("div", { className: "ais-Hits" },
                            React.createElement("div", { className: "ais-Hits-list" }, searchResults.hits.map(function (hit) {
                                return React.createElement("div", { className: "ais-Hits-item", key: "venue-".concat(hit.id) },
                                    React.createElement(VenueHit, { hit: hit }));
                            })))));
                }
                else {
                    return (React.createElement("section", { className: "search__empty" },
                        React.createElement(Suspense, { fallback: React.createElement("span", null) },
                            React.createElement(NoVenueResults, null))));
                }
            };
        };
        _this.getResultsElement = function () {
            return function (_a) {
                var nbHits = _a.nbHits;
                return nbHits === 0 ? (React.createElement(React.Fragment, null, "Show Results")) : (React.createElement(React.Fragment, null,
                    "Show ",
                    nbHits.toLocaleString(),
                    " Results"));
            };
        };
        _this.getVenuesFoundElement = function () {
            return function (_a) {
                var nbHits = _a.nbHits;
                return nbHits === 0 ? (React.createElement(React.Fragment, null, "Show Results")) : (React.createElement(React.Fragment, null,
                    nbHits.toLocaleString(),
                    " venues found"));
            };
        };
        _this.render = function () {
            var _a, _b, _c, _d, _e, _f;
            var searchState = _this.state.searchState;
            var configuration = _this.generateConfigObject();
            var URLParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
            var filterHeader = _this.getFilterHeader();
            var noOfAllergies = _this.getNumberOfSelectedAllergies();
            var CustomHits = connectStateResults(_this.getHitsLayout());
            var CustomStats = connectStats(_this.getResultsElement());
            var CustomFound = connectStats(_this.getVenuesFoundElement());
            var defaultSort = (_this.state.latLng !== null) ? "wp_venue/sort/_geoloc(".concat(_this.state.latLng, "):asc") : 'wp_venue';
            return (React.createElement("main", { className: "search" },
                React.createElement(InstantSearch, { indexName: "wp_venue", searchClient: searchClient, searchState: searchState, onSearchStateChange: _this.onSearchStateChange, createURL: searchStateToURL },
                    React.createElement(Configure, __assign({}, configuration)),
                    React.createElement("div", { style: { display: "none" } },
                        React.createElement(SearchBox, null)),
                    React.createElement("section", { className: "search__inner" },
                        React.createElement("div", { className: "container" },
                            React.createElement(CustomHits, null),
                            React.createElement("aside", { className: "search__stats" },
                                _this.state.selectedArea && (React.createElement("div", { className: "search__area" },
                                    "Venues near",
                                    React.createElement("svg", null,
                                        React.createElement("use", { xlinkHref: "#icon--area" })),
                                    React.createElement("span", null, _this.state.selectedArea))),
                                React.createElement("div", { className: "search__found" },
                                    React.createElement(CustomFound, null))),
                            React.createElement("aside", { className: "search__triggers" },
                                React.createElement("button", { className: "btn btn--filter", onClick: function () { return _this.toggleFilterModal({ filtersModalOpen: true }, true); } },
                                    "Filters",
                                    React.createElement("svg", { className: "btn__icon" },
                                        React.createElement("use", { xlinkHref: "#icon--filter" }))),
                                React.createElement(SortBy, { defaultRefinement: defaultSort, items: [
                                        { value: defaultSort, label: 'Sort by' },
                                        { value: 'wp_venue/sort/rating_score:desc', label: 'Rating desc.' },
                                        { value: 'wp_venue/sort/rating_score:asc', label: 'Rating asc.' },
                                    ] }),
                                React.createElement("button", { className: "btn btn--filter", onClick: function () { return _this.toggleFilterModal({ allergenModalOpen: true }, true); } },
                                    noOfAllergies,
                                    " Allergens",
                                    React.createElement("svg", { className: "btn__icon" },
                                        React.createElement("use", { xlinkHref: "#icon--stop" })))),
                            React.createElement("aside", { className: "search__pagination" },
                                React.createElement(Pagination, { defaultRefinement: 1, showFirst: false, showLast: false })))),
                    React.createElement("aside", { id: "location-overlay", className: "filter-overlay filter-overlay--search", style: {
                            display: _this.state.locationModalOpen ? 'block' : 'none'
                        } },
                        React.createElement("div", { className: "filter-overlay__inner" },
                            React.createElement("nav", { id: "site-nav", className: "site-nav" },
                                React.createElement("div", { className: "container" },
                                    React.createElement("a", { href: "/" },
                                        React.createElement("svg", { className: "logo" },
                                            React.createElement("use", { xlinkHref: "#logo--dark" }))))),
                            React.createElement(Suspense, { fallback: React.createElement("div", null) },
                                React.createElement(LocalitySearch, { searchTerm: URLParams.location || null, onSelected: _this.changeLatLng })))),
                    React.createElement("aside", { id: "allergen-overlay", className: "filter-overlay", style: {
                            display: _this.state.allergenModalOpen ? 'flex' : 'none'
                        } },
                        React.createElement("div", { onClick: function () { return _this.toggleFilterModal({ allergenModalOpen: false }, false); }, className: "filter-overlay__backdrop" }),
                        React.createElement("div", { className: "filter-overlay__inner" },
                            React.createElement("button", { className: "filter-overlay__close", type: "button", onClick: function () { return _this.toggleFilterModal({ allergenModalOpen: false }, false); } },
                                React.createElement("span", null, "Close"),
                                React.createElement("svg", null,
                                    React.createElement("use", { xlinkHref: "#icon--close" }))),
                            filterHeader,
                            React.createElement(Suspense, { fallback: _this.defaultFallback() },
                                React.createElement(TaxonomySelection, { taxonomySlug: "kitchen-free-from", refinementSlug: "kitchen_free_from", preSelected: ((_a = _this.state.searchState.refinementList) === null || _a === void 0 ? void 0 : _a.kitchen_free_from) || null, introTitle: "Kitchen free-from", introContent: "Select allergens to find venues that do not use those ingredients", onSelected: _this.updateRefinements })),
                            React.createElement(Suspense, { fallback: _this.defaultFallback() },
                                React.createElement(TaxonomySelection, { taxonomySlug: "menu-free-from", refinementSlug: "menu_free_from", preSelected: ((_b = _this.state.searchState.refinementList) === null || _b === void 0 ? void 0 : _b.menu_free_from) || null, introTitle: "Meals good for", introContent: "Select allergens to find food venues that can cater for you", onSelected: _this.updateRefinements })),
                            React.createElement("div", { className: "filter-overlay__actions" },
                                React.createElement("button", { type: "button", className: "btn", onClick: function () { return _this.toggleFilterModal({ allergenModalOpen: false }, false); } },
                                    React.createElement(CustomStats, null))))),
                    React.createElement("aside", { id: "other-filters-overlay", className: "filter-overlay", style: {
                            display: _this.state.filtersModalOpen ? 'flex' : 'none'
                        } },
                        React.createElement("div", { onClick: function () { return _this.toggleFilterModal({ filtersModalOpen: false }, false); }, className: "filter-overlay__backdrop" }),
                        React.createElement("div", { className: "filter-overlay__inner" },
                            React.createElement("button", { className: "filter-overlay__close", type: "button", onClick: function () { return _this.toggleFilterModal({ filtersModalOpen: false }, false); } },
                                React.createElement("span", null, "Close"),
                                React.createElement("svg", null,
                                    React.createElement("use", { xlinkHref: "#icon--close" }))),
                            filterHeader,
                            React.createElement(TaxonomySelection, { taxonomySlug: "venue-type", refinementSlug: "venue_type", preSelected: ((_c = _this.state.searchState.refinementList) === null || _c === void 0 ? void 0 : _c.venue_type) || null, introTitle: "Venue type", onSelected: _this.updateRefinements }),
                            React.createElement(TaxonomySelection, { taxonomySlug: "venue-feature", refinementSlug: "venue_feature", preSelected: ((_d = _this.state.searchState.refinementList) === null || _d === void 0 ? void 0 : _d.venue_feature) || null, introTitle: "Features", onSelected: _this.updateRefinements }),
                            React.createElement(TaxonomySelection, { taxonomySlug: "meal-type", refinementSlug: "meal_type", preSelected: ((_e = _this.state.searchState.refinementList) === null || _e === void 0 ? void 0 : _e.meal_type) || null, introTitle: "Meal types", onSelected: _this.updateRefinements }),
                            React.createElement(TaxonomySelection, { taxonomySlug: "cuisine-type", refinementSlug: "other_cuisine", preSelected: ((_f = _this.state.searchState.refinementList) === null || _f === void 0 ? void 0 : _f.other_cuisine) || null, introTitle: "Cuisine", onSelected: _this.updateRefinements }),
                            React.createElement("div", { className: "filter-overlay__actions" },
                                React.createElement("button", { type: "button", className: "btn", onClick: function () { return _this.toggleFilterModal({ filtersModalOpen: false }, false); } },
                                    React.createElement(CustomStats, null))))))));
        };
        return _this;
    }
    SearchPage.prototype.componentDidMount = function () {
        window.addEventListener('popstate', this.onPopState);
        var URLParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        if (typeof URLParams.location !== "undefined" && URLParams.location) {
            this.setState({ locationModalOpen: true });
        }
    };
    SearchPage.prototype.componentWillUnmount = function () {
        clearTimeout(this.debouncedSetState);
        window.removeEventListener('popstate', this.onPopState);
    };
    return SearchPage;
}(React.PureComponent));
export default SearchPage;
